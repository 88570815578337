
.input-default {
  padding: 10px;
  font-size: 16px; 
  font-weight: 400;
}
.input-default::placeholder {
  color: #94A3B8; /* Placeholder text color */
}
body {
  font-family: 'Inter', sans-serif;
}
.input-focused {
  color: #0F172A;
  padding: 10px;
  font-size: 16px; 
  font-weight: 400;
}
.ButText .css-1wmfcrv-MuiButtonBase-root-MuiIconButton-root{
  padding-bottom: 5px;
}
.hover-box {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  background-color: transparent;
  transition: background-color 0.3s ease; /* Smooth transition */
}

.hover-box:hover {
  background-color: #f0f0f0; /* Background color on hover */
}

.rbc-header {
  font-size: 14px;      /* Change font size */
  color:#0F172A  ;       /* Change color */
  font-weight: 400;    /* Optional: Make font bold */
}
@media (max-width: 600px) {
  .input-default,
  .input-focused {
    font-size: 12px; 
  }
}

.button-style {
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid #CBD5E1;
  margin-bottom: 10px;
  text-transform: none;
  width: 100%;
  height: 56px;
  position: relative;
  display: flex;
  align-items: center;
}

.button-style img {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 16px;
}
.button-icon {
  height: 20px;     
  width: 20px;      
  position: absolute;
  right: 16px;      
}
.image-container {
  position: fixed;
  right: 0;
  top: 0;
  width: 50%;
  height: 100vh; 
  background: url('../assets/ic_password_invisible.png') no-repeat center center;
  background-size: cover;
}

.form-container {
  margin-right: 50%; 
  overflow-y: auto;
}

.spanText{
  color:#94A3B8; 
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
}
@media (max-width: 600px) {
  .spanText{
    font-size: 16px;
  }
}
.button-style2 {
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid #CBD5E1;
  margin-bottom: 10px;
  text-transform: none;
  width: 100%;
  height: 56px;
  position: relative;
  display: flex;
  align-items: center; 
}
.right-half {
position: fixed;
top: 0;
right: 0;
width: 50%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
overflow: hidden; 
flex-direction: column;
}

.right-half img {
width: 100%;
height: 100%;
min-height: 100%;

}


.left-half {
position: relative;
width: 50%;            
height: 100vh;
padding: 0px 0 0 0;
background-color: white;
border-bottom-left-radius: 24px;
border-top-left-radius: 24px;
overflow-y: auto;  

display: flex;
flex-direction: column;
align-items: center;

scrollbar-width: none;  
-ms-overflow-style: none;
}

.left-half::-webkit-scrollbar {
display: none;        
}


@media (max-width: 800px) {
  .left-half,
  .right-half {
    position: static;
    width: 100%;
    height: auto;
    border-radius: 0;
    overflow: visible; /* No independent scrolling */
  }

  .outer-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto; /* Enable scrolling for the entire container */
  }

  .right-half img {
    height: auto;
    max-height: 100%;
  }
}
.custom-style {
width: 70%;
margin-top: 20px;
}

@media (max-width: 800px) {
.custom-style {
  width: 90%; 
}
}
.dialog-paper {
width: 25%;
max-width: none; 
border-radius: 35px;
}

@media (max-width: 800px) {
.dialog-paper {
  width: 90%;
}
}
.outer-div {
background-color: #ECFDF5;
height: 15%;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}
.dialog .css-ypiqx9-MuiDialogContent-root{
padding: 0;
} 
.driver-app-container {
height: 100vh;
display: flex;
flex-direction: column;
background-color:#ECFDF5 ;
width:100vw
}
.driver-app-container-failure {
height: 100vh;
display: flex;
flex-direction: column;
background-color:#FEE2E2 ;

width:100vw
}

.driver-top-section {
position: relative;
height: 60%;
width: 100%;
display: flex;
justify-content: center;
align-items: flex-end;
}

.driver-top-section .driver-main-image {
width: 50%; 
height:90%
}

.driver-top-section .driver-logo-image {
position: absolute;
top: 10px;
left: 10px;
width: 15%;
}

.driver-bottom-section {
height: 40%;
width: 100%;
background-color:#ECFDF5 ;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
 text-align: center;

}

.driver-bottom-section .driver-main-title {
font-size:35px;
font-weight: 700;
margin-bottom: 10px;
color:#87C122;
}

.driver-bottom-section .driver-sub-title1 {
font-size: 25px;
font-weight: 400;
color:#0F172A;
padding:0;
margin:0;
margin-bottom:10px


}
.driver-bottom-section .driver-sub-title2 {
font-size: 25px;
font-weight: 400;
color:#0F172A;
padding:0;
margin: 0;
margin-bottom: 30px;
}
.driver-bottom-section .driver-action-button {
padding: 15px 20px;
font-size: 20px;
font-weight: 400;
cursor: pointer;
border-radius: 11px;
background-color:#1D6FB8 ;
color:#FFFF;
width:25%;
border: none;
box-shadow: none; 
outline: none; 
}
@media only screen and (max-width: 1024px) {
.driver-top-section .driver-main-image {
  width: 60%; 
}

.driver-top-section .driver-logo-image {
  width: 18%; 
}

.driver-bottom-section .driver-main-title {
  font-size: 2rem;
}

.driver-bottom-section .driver-sub-title1,
.driver-bottom-section .driver-sub-title2 {
  font-size: 1.3rem;
}

.driver-bottom-section .driver-action-button {
  width: 30%;
  font-size: 1.1rem;
}
}

@media only screen and (max-width: 768px) {
.driver-top-section {
  height: 55vh; 
}

.driver-top-section .driver-main-image {
  width: 70%;
  height: auto;
}

.driver-top-section .driver-logo-image {
  width: 25%;
}

.driver-bottom-section .driver-main-title {
  font-size: 1.8rem;
}

.driver-bottom-section .driver-sub-title1,
.driver-bottom-section .driver-sub-title2 {
  font-size: 1.2rem;
}

.driver-bottom-section .driver-action-button {
  width: 35%;
  font-size: 1rem;
}
}

@media only screen and (max-width: 480px) {
.driver-top-section {
  height: 45vh; 
}

.driver-top-section .driver-main-image {
  width: 85%;
  height: auto;
}

.driver-top-section .driver-logo-image {
  width: 38%;
}

.driver-bottom-section .driver-main-title {
  font-size: 1.5rem;
}

.driver-bottom-section .driver-sub-title1,
.driver-bottom-section .driver-sub-title2 {
  font-size: 1rem;
}

.driver-bottom-section .driver-action-button {
  width: 50%;
  font-size: 0.9rem;
}
}



.driver-top-section-failure {
position: relative;
height: 60%;
width: 100%;
display: flex;
justify-content: center;
align-items: flex-end; 
}

.driver-top-section-failure .driver-main-image-failure {
width: 25%;
height:90%
}

.driver-top-section-failure .driver-logo-image-failure {
position: absolute;
top: 10px;
left: 10px;
width: 15%;
}

.driver-bottom-section-failure {
height: 40%;
width: 100%;
background-color:#ECFDF5 ;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
 text-align: center;

}

.driver-bottom-section-failure .driver-main-title1-failure {
font-size:30px;
font-weight: 600;
margin:0;
padding:0;
margin-bottom: 10px;
color:#DC2626;

}
.driver-bottom-section-failure .driver-main-title2-failure {
font-size:30px;
font-weight: 600;
padding:0;
margin:0;
margin-bottom: 10px;
color:#DC2626;
}
.driver-bottom-section-failure .driver-sub-title1-failure {
font-size: 20px;
font-weight: 400;
color:#475569;

padding:0;
margin:0;
margin-bottom:10px

}
.driver-bottom-section-failure .driver-sub-title2-failure {
font-size: 20px;
font-weight: 400;
color:#475569;
padding:0;
margin: 0;
margin-bottom: 30px;
}
.driver-bottom-section-failure .driver-action-button-failure {
padding: 15px 20px;
font-size: 20px;
font-weight: 400;
cursor: pointer;
border-radius: 11px;
background-color:#DC2626 ;
color:#FFFF;
width:25%;
display:flex;
justify-content: center;
align-items: center;
border: none; 
box-shadow: none;
outline: none; 
}

.retry{
width:10%;
margin-right:5px;
}
@media only screen and (max-width: 1200px) {
.driver-top-section-failure .driver-main-image-failure {
  width: 30%; 
}
}

@media only screen and (max-width: 768px) {
.driver-top-section-failure {
  height: 50%;
}

.driver-top-section-failure .driver-main-image-failure {
  width: 50%;
}

.driver-bottom-section-failure {
  height: 50%; 
  width: 95vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.driver-bottom-section-failure .driver-main-title1-failure,
.driver-bottom-section-failure .driver-main-title2-failure {
  font-size: 24px; 
}

.driver-bottom-section-failure .driver-sub-title1-failure,
.driver-bottom-section-failure .driver-sub-title2-failure {
  font-size: 18px; 
}

.driver-bottom-section-failure .driver-action-button-failure {
  width: 40%; 
  font-size: 18px; 
}
}

@media only screen and (max-width: 480px) {
.driver-app-container-failure {
  height: 100vh;
  display: flex;
  flex-direction: column;

width:100vw;
justify-content: center;
align-items: center;
}

.driver-top-section-failure {
  height: 45%; 
}

.driver-top-section-failure .driver-main-image-failure {
  width: 70%; 
}

.driver-top-section-failure .driver-logo-image-failure {
  width: 38%; 
}

.driver-bottom-section-failure {
  height: 50%; 
  width: 95vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.driver-bottom-section-failure .driver-main-title1-failure,
.driver-bottom-section-failure .driver-main-title2-failure {
  font-size: 20px; 
}

.driver-bottom-section-failure .driver-sub-title1-failure,
.driver-bottom-section-failure .driver-sub-title2-failure {
  font-size: 16px; 
}

.driver-bottom-section-failure .driver-action-button-failure {
  width: 60%; 
  font-size: 16px; 
}

.retry {
  width: 12%;
  margin-right:3px;

}
}
@media only screen and (max-width: 480px) {
  .driver-logo-image-failure{
    width:'25%'
  }
}
.driver-app-container-review {
height: 100vh;
display: flex;
flex-direction: column;
background-color:#FFFF;
width:100vw
}

.driver-top-section-review {
position: relative;
height: 50%;
width: 100%;
display: flex;
justify-content: center;
align-items: flex-end; 
}

.driver-top-section-review .driver-main-image-review {
width: 20%; 
height:90%
}

.driver-top-section-review .driver-logo-image-review {
position: absolute;
top: 10px;
left: 10px;
width: 15%;
}

.driver-bottom-section-review {
height: 50%;
width: 100%;
background-color:#FFFF ;
display: flex;
flex-direction: column;
align-items: center;
 text-align: center;

}

.driver-bottom-section-review .driver-main-title-review {
margin-top: 20px;
font-size:30px;
font-weight: 700;
margin-bottom: 10px;
color:#87C122;
}

.driver-bottom-section-review .driver-sub-title1-review {
font-size: 22px;
font-weight: 400;
color:#0F172A;
padding:0;
margin:0;
margin-bottom:10px

}
.driver-bottom-section-review .driver-sub-title2-review {
font-size: 22px;
font-weight: 400;
color:#0F172A;
padding:0;
margin: 0;
margin-bottom: 80px;
}
.driver-bottom-section-review .driver-action-button-review {
padding: 15px 20px;
font-size: 20px;
font-weight: 400;
cursor: pointer;
border-radius: 11px;
background-color:#1D6FB8 ;
color:#FFFF;
width:25%;
border: none; 
box-shadow: none; 
outline: none;
}

@media only screen and (max-width: 768px) {
.driver-app-container-review {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color:#FFFF;
width:100vw
}
.driver-top-section-review .driver-main-image-review {
  width: 35%; 
}

.driver-bottom-section-review .driver-main-title-review {
  font-size: 25px; 
}

.driver-bottom-section-review .driver-sub-title1-review,
.driver-bottom-section-review .driver-sub-title2-review {
  font-size: 20px;
  margin-bottom: 40px;
}

.driver-bottom-section-review .driver-action-button-review {
  width: 50%;
}
}


@media only screen and (max-width: 480px) {
.driver-app-container-review {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color:#FFFF;
}
.driver-top-section-review {
  height: 40%; 
}
.driver-top-section-review .driver-main-image-review {
  width: 50%;
}
.driver-top-section-review .driver-logo-image-review {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 38%; 
}
.driver-bottom-section-review {
 height:60%;
 display:flex;
 justify-content: center;
 align-items: center;
}

.driver-bottom-section-review .driver-main-title-review {
  font-size: 22px; 
}

.driver-bottom-section-review .driver-sub-title1-review,
.driver-bottom-section-review .driver-sub-title2-review {
  font-size: 18px;
  margin-bottom: 30px;
}

.driver-bottom-section-review .driver-action-button-review {
  width: 70%; 
}
}
.driver-app-container-reject {
height: 100vh;
display: flex;
flex-direction: column;
background-color:#FFFF ;
width:100%;

}

.driver-top-section-reject {
position: relative;
height: 90%;
width: 100%;
display: flex;
justify-content: center;
align-items: flex-end;
}

.driver-top-section-reject .driver-main-image-reject {
width: 100%;
margin-top: 50px;
max-width: 318px;
height:423px
}
@media (max-width: 480px) {
  .driver-top-section-reject .driver-main-image-reject {
    width: 60% !important; 
    height: 323px; 
  }
}
.driver-top-section-reject .driver-logo-image-reject {
position: absolute;
top: 10px;
left: 10px;
width: 161px;
height: 56px;
}

.driver-bottom-section-reject {
height: 50%;
width: 100%;
background-color:#FFFF ;
display: flex;
flex-direction: column;
margin-top: 40px;
align-items: center;
 text-align: center;

}

.driver-bottom-section-reject .driver-main-title1-reject {
font-size:30px;
font-weight: 700;
margin:0;
padding:0;
letter-spacing: -0.5%;
margin-bottom: 10px;
color:#DC2626;

}
.driver-bottom-section-reject .driver-main-title2-reject {
font-size:30px;
font-weight: 600;
padding:0;
margin:0;
margin-bottom: 10px;
color:#DC2626;
}
.driver-bottom-section-reject .driver-sub-title1-reject {
font-size: 24px;
font-weight: 400;
color:#475569;

padding:0;
margin:0;
margin-bottom:10px

}
.driver-bottom-section-reject .driver-sub-title2-reject {
font-size: 24px;
font-weight: 400;
color:#475569;
padding:0;
margin: 0;
margin-bottom: 30px;
}
.driver-bottom-section-reject .driver-action-button-reject {
font-size: 20px;
margin-top: 30px;
font-weight: 400;
cursor: pointer;
border-radius: 11px;
background-color:#DC2626 ;
color:#FFFF;
width:100%;
max-width: 440px;
height: 56px;
display:flex;
justify-content: center;
align-items: center;
border: none; 
box-shadow: none; 
outline: none; 
}
@media only screen and (max-width: 768px) {
.driver-top-section-reject .driver-main-image-reject {
  width: 30%; 
}

.driver-bottom-section-reject .driver-main-title1-reject {
  font-size: 25px; 
}

.driver-bottom-section-reject .driver-sub-title1-reject,
.driver-bottom-section-reject .driver-sub-title2-reject {
  font-size: 18px; 
}

.driver-bottom-section-reject .driver-action-button-reject {
  width: 40%; 
}
}


@media only screen and (max-width: 480px) {
.driver-app-container-reject {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.driver-top-section-reject {
 
  width: 100%; 
  height: 50%;
}

.driver-top-section-reject .driver-main-image-reject {
  padding-top: '20px';
  width: 40%; 
}
.driver-top-section-reject .driver-logo-image-reject {
  position: absolute;
  top: 10px; 
  left: 10px;
  width: 38%; 
}

.driver-bottom-section-reject {
  height: 50%; 
  width: 100%; 
  display: flex;
  align-items: center;
  text-align: center;
}

.driver-bottom-section-reject .driver-main-title1-reject {
  font-size: 22px; 
}

.driver-bottom-section-reject .driver-sub-title1-reject,
.driver-bottom-section-reject .driver-sub-title2-reject {
  font-size: 14px; 
}

.driver-bottom-section-reject .driver-action-button-reject {
  width: 70%;
}
}

.outer-div-planride {
  background-color: #ECFDF5;
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  height:100%;
  flex: 1;
}

.input-field {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 12px;
  border-radius: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #334155;
  transition: all 0.3s ease;
  width: 100%; 
}

.input-field .icon {
  margin-right: 10px; 
  width: 24px; 
  height: 24px; 
}
.icon2{
  margin-right: 10px; 
  width: 20px; 
  height: 21px; 
  margin-left: 2px;
}
.input-field .text {  
  margin-right: auto; 
}

.input-field:nth-child(1) .text,
.input-field:nth-child(2) .text {
  color: #94a3b8;
}
.input-field:nth-child(3) .text,
.input-field:nth-child(4) .text,
.input-field:nth-child(5) .text,
.input-field:nth-child(6) .text {
  color: #334155;
  font-size: 16px;
  font-weight: 600;
}
.text-wrapper {
  display: flex;
  align-items: center;
  width: 100%; 
}

.text-wrapper .expand-icon {
  margin-left: auto;
  cursor: pointer;
  width: 16px; 
  height: 16px; 
}

.expanded {
  background-color: #f0f9ff;
}

@media (max-width: 600px) {
  .input-field {
    font-size: 14px;
    padding: 10px;
  }

  .outer-div {
    padding: 10px;
    justify-content:flex-start;
  }
  
}
* {
  box-sizing: border-box; 
  margin: 0;
  padding: 0;
}
.Planride-outer{
  background-color:#ECFDF5;
  display: flex;
  position: relative;
  height: 70vh;
  
}
.innerSecodDiv{
  display: flex;
  flex-direction: column;
  gap: 16px;
  height:80%;
   width: 100%;
 }
.map-background-div {
  flex: 1; 
  overflow: hidden;
  height: 100%;
  background-color:#ECFDF5;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;

}
@media (max-width: 600px) {
  .input-field:nth-child(3) .text,
.input-field:nth-child(4) .text,
.input-field:nth-child(5) .text,
.input-field:nth-child(6) .text {
  color: #334155;
  font-size: 14px;
  font-weight: 600;
}
  .Planride-outer {
      flex-direction: column; 
      gap: 0; 
      width:100%;
      height: fit-content;
  }
  .innerSecodDiv{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height:100%;
    padding-top: 10px;
  
  }
  .outer-div-planride,
  .map-background-div {
    flex-grow: 1;
      width: 100%; 
      height:100%;
      padding-right: 0; 
  }
}
.carPopup .css-3vnu9j-MuiDialogContent-root {
  padding:0px; 
}
.table-container {
  width: 100%;
  overflow-x: auto;
  padding: 20px;
}

.custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.custom-table th, .custom-table td {
  padding: 15px;
  text-align: center;
  border: none;
}

.header-row {
  background-color:#F8FAFC;
  border-radius: 20px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

.content-row {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1); 
  margin-bottom: 10px;
}

.content-row td {
  border-left: 0;
  border-right: 0;
  font-size: 15px;
  font-weight: 400;
}
.header-row td{
  font-size: 16px;
  font-weight: 700;
}
@media (max-width: 600px) {
  .content-row td {
    border-left: 0;
    border-right: 0;
    font-size: 12px;
    font-weight: 400;
  }
  .header-row td{
    font-size: 14px;
    font-weight: 700;
  }
}
.header-row th:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px; 
  padding: 20px;
}

.header-row th:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px; 
}

.content-row td:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px; 
}

.content-row td:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px; 
}

@media (max-width: 768px) {
  .custom-table {
    width: 100%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}
.outerTabs .css-14r7sfs{
  padding: 0px;
}
.payment-text-style {
  color: #57534E;
  font-size: 12px;
  font-weight: 400;
}
.Payment-error{
  font-size:12px;
  font-weight: 400;
  color:#64748B  ;
  padding-bottom: '20px';
}
.payment-text-container {
  display: flex;
  flex-direction: column; 
  margin-top: 20px;
  margin-bottom: 20px;
}

.payment-text-style1 {
  color:#334155  ;
  font-size: 12px;     
  font-weight: 700;    
  padding-bottom: 20px;           
}
.payment-text-style2 {
  color:#57534E;  
  font-size: 12px;    
  font-weight: 400;    
  margin: 0;      
}

.rbc-calendar,
.rbc-time-view,
.rbc-month-view,
.rbc-week-view,
.rbc-day-view {
  border: none !important;
}

.rbc-day-bg,
.rbc-day-slot,
.rbc-time-slot,
.rbc-time-slot-inner,
.rbc-row {
  border: none !important;
}

.rbc-day-bg,
.rbc-time-slot-inner,
.rbc-time-slot > .rbc-time-slot-inner {
  padding: 0 !important;
  margin: 0 !important;
}

.rbc-event,
.rbc-event-label {
  border: none !important;
}

.rbc-time-slot > .rbc-time-slot-inner,
.rbc-day-bg {
  background: none !important;
}

.rbc-header {
  border: none !important;
}


.rbc-calendar {
  border: none !important;
  box-shadow: none !important;
}

.rbc-week-view table,
.rbc-time-view table {
  border: none !important;
  border-collapse: collapse !important;
  padding: 0 !important;
}

.rbc-day-slot,
.rbc-day-bg,
.rbc-time-slot-inner,
.rbc-time-slot {
  border: none !important;
  padding: 0 !important;
}

.rbc-time-column {
  border: none !important;
}

.rbc-event,
.rbc-event-label,
.rbc-header {
  border: none !important;
  padding: 0 !important;
}

.rbc-week-view .rbc-day-slot,
.rbc-week-view .rbc-day-bg {
  border: none !important;
}

.rbc-event:hover,
.rbc-event:focus {
  border: none !important;
}

.rbc-time-column-inner {
  border: none !important;
}
.rbc-time-gutter {
  color:#64748B  !important;
}

.rbc-event-time {
  color: #64748B !important;
}

.rbc-time-gutter .rbc-day-bg:not(.rbc-off-range) {
  color: red !important;
}

.rbc-time-gutter .rbc-off-range {
  color: blue !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
}


.rbc-event {
  white-space: normal; /* Allow text to wrap */
  overflow: hidden; /* Prevent overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  font-size: 0.75em; /* Adjust font size if needed */
  line-height: 1.3em; /* Adjust line spacing */
  word-wrap: break-word; /* Ensure long words break */
}
.input-container {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 12px;
  border-radius: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #334155;
  transition: all 0.3s ease;
  width: 100%;
  box-sizing: border-box; /* Ensures padding does not affect width */
}

.input-icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.styled-input {
  border: none;
  outline: none;
  width: 100%; /* Full width */
  background: transparent; /* Match background with container */
  font-size: 16px;
  font-weight: 400;
  color: #334155; /* Text color */
}

.styled-input::placeholder {
  color: #ccc; /* Placeholder color */
}

.styled-input:focus {
  outline: none; /* Remove focus outline */
}
.styled-input::placeholder {
  font-size: 16px;
  color:#94A3B8;

}
.rbc-today {
  background-color: transparent !important;
}